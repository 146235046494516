import React from "react";
//import fuzzyFilterFactory, { onChangeInputValue } from "react-fuzzy-filter";

// these components share state and can even live in different components
//const { InputFilter, FilterResults, changeInputValue } = fuzzyFilterFactory();

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container } from '../../components/container'
import { Wrapper } from '../../components/wrapper';

const IndexPage = () => {
  return (
    <Layout>

      <SEO title="Dental Services" />

      <Wrapper>

      </Wrapper>

    </Layout>
  );
}
export default IndexPage;
